import { render, staticRenderFns } from "./ImageTextBlockBlogWrapper.vue?vue&type=template&id=06f8b9a6&scoped=true"
import script from "./ImageTextBlockBlogWrapper.vue?vue&type=script&lang=js"
export * from "./ImageTextBlockBlogWrapper.vue?vue&type=script&lang=js"
import style0 from "./ImageTextBlockBlogWrapper.vue?vue&type=style&index=0&id=06f8b9a6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06f8b9a6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageTextBlockBlog: require('/usr/src/app/components/image-text-block/ImageTextBlockBlog.vue').default})

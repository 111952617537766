
export default {
  name: 'ImageTextBlockBlog',
  props: {
    mobileImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    desktopImage: {
      type: Object,
      required: false,
      default: () => ({})
    },
    headline: {
      type: Array,
      required: true
    },
    text: {
      type: Object,
      required: false,
      default: () => ({})
    },
    link: {
      type: Object,
      required: false,
      default: () => ({})
    },
    backgroundColor: {
      type: String,
      required: false,
      default: '#FAF9F6'
    },
    textColor: {
      type: String,
      required: false,
      default: '#383838'
    },
    tag: {
      type: Array,
      required: false,
      default: () => ([])
    }
  },
  data () {
    return {
      emptyLink: {
        url: '',
        linktype: 'story',
        fieldtype: 'multilink'
      }
    }
  },
  computed: {
    tagData () {
      return this.tag[0]
    },
    colorsCss () {
      return {
        '--background-color': this.backgroundColor,
        '--text-color': this.textColor
      }
    }
  }
}


export default {
  name: 'ImageTextBlockWrapper',
  props: {
    content: {
      type: Array,
      required: true
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '2'
    }
  }
}
